@import '../../../styles/vars';

.wrapper {
  background-color: #e8e8e8;
  color: $color-grey-dark;
  font-size: 12px;
  padding-bottom: 15px;
}
.list {
  display: flex;
  flex-wrap: wrap;
}
.listItem {
  margin-top: 15px;
  margin-right: 30px;
}